/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import qs from 'qs';
import baseApi, { StrapiId, getListModel } from '../services';
import get from 'lodash/get';
import { Branch } from '../services/branch';
import { DisplayTemplate } from '../services/display-templates';
import { Display } from '../services/displays';

interface AuthenticationRequest {
  displayId: string;
  code: string;
}

const playerApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    authenticate: builder.query<any, AuthenticationRequest>({
      query: (data) => ({
        url: '/display/authenticate',
        method: 'POST',
        body: { data },
      }),
    }),
    registerDisplay: builder.mutation<Display, Partial<Display>>({
      query(data) {
        return {
          url: `/displays`,
          method: 'POST',
          body: { data },
        };
      },
    }),
    statusUpdate: builder.query<any, StrapiId>({
      query: (id) => `/display/${id}/status`,
    }),
    getDisplayCode: builder.query<Partial<Display>, Partial<Display>>({
      queryFn: async (req, api) => {
        const res = await api.dispatch(
          playerApi.endpoints.registerDisplay.initiate(req)
        );
        return res;
      },
    }),
    getDisplayGroups: builder.query<any, StrapiId>({
      query: (id) => {
        const query = { populate: { groups: { fields: ['id'] } } };
        return `/displays/${id}?${qs.stringify(query, {
          encodeValuesOnly: true,
        })}`;
      },
      transformResponse: (res, meta, arg) => ({
        data: getListModel(get(res, 'data.attributes.groups.data', [])),
        meta: get(res, 'meta', {}),
        original: get(res, 'data'),
      }),
    }),
    getBranchTemplate: builder.query<DisplayTemplate, Branch['id']>({
      providesTags: (result, error, arg) => [
        { type: 'DisplayTemplates', id: result?.id || 'LIST' },
      ],
      query: (branchId) =>
        `/display-templates?${qs.stringify(
          { branchId, default: true },
          { encodeValuesOnly: true }
        )}`,
      transformResponse: (res, meta, arg) =>
        get(res, 'data', {}) as DisplayTemplate,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDisplayGroupsQuery,
  useGetBranchTemplateQuery,
  useAuthenticateQuery,
  useDisplayCodeMutation,
  useGetDisplayCodeQuery,
  useRegisterDisplayMutation,
  useStatusUpdateQuery,
} = playerApi;

export default playerApi;
