/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { AnyAction, AsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import * as jose from 'jose';
import {
  union,
  assignIn,
  get,
  result,
  keyBy,
  pick,
  set,
  isEqual,
  matches,
} from 'lodash';
import storage from 'store';
// in an ES6 environment:
import { ClientJS } from 'clientjs';

import playerApi from './service';
import appointmentsApi from '../services/appointments';
import { PlayerRootState } from './store';
import { BrowserDetails, ScreenDetails } from '../services/displays';
import { getAppoxScreenAspectRatio } from './utils';
import { AppState, APP_REDUCER_NAME } from '../app/slice';

/** CONTEXT: We are using the same AppState [APP_REDUCER_NAME] as the portal so that shared selectors can be used
 * The branch.groups property of AppState is required for the appintments block to work
 */

export type PlayerAppState = Pick<AppState, 'branch'> & {
  auth: {
    isAuthenticated: boolean;
    jwt?: string;
    id?: string;
  };
  displayId?: string;
  browserDetails?: BrowserDetails;
  screenDetails?: ScreenDetails;
};

// Create a new ClientJS object
const clientJs = new ClientJS();
const TOKEN_KEY = 'player_ctx';

const getStateFromObject = (data: object) => ({
  displayId: get(data, 'displayId'),
  auth: {
    isAuthenticated: !!get(data, 'accessToken'),
    jwt: get(data, 'accessToken'),
    id: get(data, 'id'),
  },
  branch: {
    id: get(data, 'branchId'),
    groups: get(data, 'groups', []),
  },
});

const initFromStorage = (): PlayerAppState => {
  const browserInfo = {
    browserDetails: {
      browserName: clientJs.getBrowser(),
      browserMajorVersion: clientJs.getBrowserMajorVersion(),
      browserFullVersion: clientJs.getBrowserVersion(),
      // Operating system name. Examples: 'Mac OS X', 'Android'.
      os: clientJs.getOS(),
      osVersion: clientJs.getOSVersion(),
      // Device. For desktop/laptop devices, the value will be "Other". Example: 'Samsung SM-J330F'.
      device: clientJs.getDevice() || 'Other',
      userAgent: clientJs.getUserAgent(),
    },
    screenDetails: {
      aspectRatio: getAppoxScreenAspectRatio(),
      width: window.screen.width * window.devicePixelRatio,
      height: window.screen.height * window.devicePixelRatio,
    },
  };
  try {
    if (!storage.get(TOKEN_KEY)) throw new Error('no display_ctx');
    const data = jose.decodeJwt(storage.get(TOKEN_KEY));
    const newState = getStateFromObject(data);
    return {
      ...newState,
      ...browserInfo,
    };
  } catch (e) {
    storage.remove(TOKEN_KEY); // jwt is invalid clear it from storage
    return { auth: { isAuthenticated: false }, ...browserInfo };
  }
};

export const slice = createSlice({
  name: APP_REDUCER_NAME,
  initialState: initFromStorage(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      playerApi.endpoints.authenticate.matchFulfilled,
      (state, action) => {
        const token = get(action, 'payload.data.jwt');
        if (!token) return;
        const data = jose.decodeJwt(token) as any;
        const newState = getStateFromObject({
          ...data,
          groups: get(action, 'payload.data.groups', []),
        });
        state.branch = newState.branch;
        state.auth = newState.auth;
        state.displayId = newState.displayId;
        // store the jwt  as display_ctx in local storage
        storage.set(TOKEN_KEY, token);
      }
    );
    builder.addMatcher(
      playerApi.endpoints.getDisplayGroups.matchFulfilled,
      (state, action) => {
        set(state, 'branch.groups', action.payload.data);
      }
    );
    builder.addMatcher(
      (action) =>
        [
          // if any of these actions are rejected, check if the error is a 401
          playerApi.endpoints.getBranchTemplate.matchRejected,
          playerApi.endpoints.getDisplayGroups.matchRejected,
          playerApi.endpoints.statusUpdate.matchRejected,
          appointmentsApi.endpoints.getAppointments.matchRejected,
        ].some((matcher) => matcher(action)),
      (state, { payload }) => {
        const error = get(payload, 'data.error');
        if (
          payload?.status === 401 ||
          error?.status === 401 ||
          error?.name === 'UnauthorizedError'
        ) {
          state.auth.isAuthenticated = false;
          storage.remove(TOKEN_KEY);
        }
      }
    );
  },
});

export const {} = slice.actions;

export const selectPlayerAppState = (state: PlayerRootState) => state.app;
export const selectAuth = createSelector(
  selectPlayerAppState,
  (state) => state.auth
);
export const selectDisplayInfo = createSelector(
  selectPlayerAppState,
  (state) => ({
    screen: state.screenDetails,
    device: { browserDetails: state.browserDetails },
  })
);
export const selectDisplayId = createSelector(
  selectPlayerAppState,
  (state) => state.displayId
);

export { clientJs };
export default slice;
