/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Player } from './views';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<Player />);
