function fareyApproximation(val, lim) {
  let lower = [0, 1];
  let upper = [1, 0];

  while (true) {
    let mediant = [lower[0] + upper[0], lower[1] + upper[1]];

    if (val * mediant[1] > mediant[0]) {
      if (lim < mediant[1]) {
        return upper;
      }
      lower = mediant;
    } else if (val * mediant[1] === mediant[0]) {
      if (lim >= mediant[1]) {
        return mediant;
      }
      return lower[1] < upper[1] ? lower : upper;
    } else {
      if (lim < mediant[1]) {
        return lower;
      }
      upper = mediant;
    }
  }
}
/** Returns approx screen aspect ratio as a string. */
export const getAppoxScreenAspectRatio = () => {
  const width = window.screen.width * window.devicePixelRatio;
  const height = window.screen.height * window.devicePixelRatio;
  const ratio = fareyApproximation(width / height, Math.max(width, height));
  return `${ratio[0]}:${ratio[1]}`;
};
