/**
 * Copyright 2023 ALPHAGUARD CONSULTING, LLC.  All rights reserved.
 * Use of this source code is governed by a Commercial License Agreement
 * license can be found in the LICENSE file or contact legal@alphaguard.io
 */

import React, { Suspense, lazy } from 'react';
import {
  useGetBranchTemplateQuery,
  useGetDisplayGroupsQuery,
  useStatusUpdateQuery,
} from '../service';
import { useBranch } from '../../hooks/branch';
import { Navigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../slice';

const DisplayTemplate = lazy(() => import('../../components/DisplayTemplate'));
const refetchGroupsInterval = 20000; // 20 seconds
const reportStatusInterval = 60000; // 1 minute
const refetchTemplateInterval = 600000; // 10 minutes

const TemplateSnapshot = React.memo(DisplayTemplate);
const PlayerTemplate = () => {
  const branch = useBranch();
  const { id: displayDbId } = useSelector(selectAuth);

  // report the status of the display
  useStatusUpdateQuery(displayDbId, {
    skip: !displayDbId,
    pollingInterval: reportStatusInterval,
  });

  const { isLoading: isLoadingTemplate, data: templateRes } =
    useGetBranchTemplateQuery(branch?.id, {
      skip: !branch?.id,
      pollingInterval: refetchTemplateInterval,
    });

  // we need to update the display groups for the template appoinments
  useGetDisplayGroupsQuery(displayDbId, {
    skip: !displayDbId,
    pollingInterval: refetchGroupsInterval,
  });

  return (
    <Suspense>
      <Box h="100vh" w="100vw" overflow="hidden">
        <TemplateSnapshot
          isLive
          template={templateRes}
          isLoading={isLoadingTemplate}
        />
      </Box>
    </Suspense>
  );
};

export default PlayerTemplate;
